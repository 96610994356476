import React from "react";

import { PastAuctionsPage as seo } from "../helpers/seo";

import useAuctions from "../hooks/use-auctions";

import SEO from "../components/SEO";
import PastAuctions from "../components/PastAuctions";
import CenteredPageSection from "../components/CenteredPageSection";

const PastAuctionsPage: React.FC = () => {
  const { auctions } = useAuctions();

  return (
    <>
      <SEO title={seo.title} description={seo.description} />
      <CenteredPageSection
        id="past-auctions"
        fullWidthClassNames="bg-clhbid-orange"
      >
        <h1 className="text-2xl lg:text-4xl">Past Sales</h1>
      </CenteredPageSection>
      <CenteredPageSection>
        <PastAuctions pastAuctions={auctions.past_auctions} />
      </CenteredPageSection>
    </>
  );
};

export default PastAuctionsPage;
